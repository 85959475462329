import React from 'react';

import Layout from '../components/Layout';
import ResetPassword from '../components/ResetPassword';
import { normalizeParams } from '../helpers';

const ResetPass = ({ location }) => {
  const { token, email } = normalizeParams(location);
  return (
    <Layout>
      <ResetPassword token={token} email={email} />
    </Layout>
  );
};

export default ResetPass;
