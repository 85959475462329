import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'gatsby';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Requests from '../actions';
import IconPassword from '../assets/images/ic-pass.inline.svg';
import IconEyePass from '../assets/images/ic-eye.inline.svg';
import iconSuccess from '../assets/images/success.png';
import iconBack from '../assets/images/back.png';
import Button from './Button';

const ResetPassword = ({ token, email }) => {
  const { register, handleSubmit, watch } = useForm();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isConfirmShown, setIsConfirmShown] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ password, confirm }) => {
    if (password !== confirm) {
      toast.error('Passwords do not match', {
        hideProgressBar: true,
        className: 'toast',
      });
    } else {
      setLoading(true);
      const decodedEmail = decodeURIComponent(email);
      const response = await Requests.resetPass({ password, token, email: decodedEmail });
      setLoading(false);
      if (response.errorMessage) {
        toast.error(response.errorMessage, {
          hideProgressBar: true,
          className: 'toast',
        });
      } else {
        setIsCompleted(true);
      }
    }
  };

  return (
    <section className="login">
      <div className="login-wrapper_outer">
        {!isCompleted ? (
          <>
            <div className="login-title_wrapper">
              <p className="login-title_par">Please enter and confirm new password.</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={`login-form_container_input ${
                  watch('confirm') && watch('password') !== watch('confirm')
                    ? 'login-form_container_input--error'
                    : ''
                }`}
              >
                <IconEyePass
                  className="icon-eye-reset-pass"
                  onClick={() => setIsPasswordShown(!isPasswordShown)}
                />
                <IconPassword className={watch('password') ? 'active-svg' : ''} />
                <input
                  type={isPasswordShown ? 'text' : 'password'}
                  className="login-input__username"
                  placeholder="New password"
                  name="password"
                  ref={register({ required: true })}
                />
              </div>
              <div
                className={`login-form_container_input ${
                  watch('confirm') && watch('password') !== watch('confirm')
                    ? 'login-form_container_input--error'
                    : ''
                }`}
              >
                <IconEyePass
                  className="icon-eye-reset-pass"
                  onClick={() => setIsConfirmShown(!isConfirmShown)}
                />
                <IconPassword className={watch('password') ? 'active-svg' : ''} />
                <input
                  type={isConfirmShown ? 'text' : 'password'}
                  placeholder="Confirm password"
                  className="login-input__password"
                  name="confirm"
                  ref={register({ required: true })}
                />
              </div>
              <Button title="Submit" loading={loading} fullWidth />
            </form>
          </>
        ) : (
          <div className="forgotPassword_wrapper-inner forgotPassword_wrapper-inner--success">
            <img src={iconSuccess} alt="Thank You." />
            <h1>Thank You.</h1>
            <p>Your password was successfully reset. Please use new one.</p>
            <Link to="/">
              <img src={iconBack} alt="back" />
              Back
            </Link>
          </div>
        )}
      </div>
      <ToastContainer />
    </section>
  );
};

export default ResetPassword;
